<template>
  <div>
    <el-card>
      <all-log :dialogLog="dialogLog" />

      <div class="menu-box">
        <h2>服务项目管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()">添加服务项目</el-button>
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="name" label="服务项目名称">
            </el-table-column>

            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      customClass="customWidth"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="name"
          label="服务项目名称"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入服务项目名称"
            @change="handleChange"
            v-model="form.name"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="type_id"
          label="服务类型"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="form.type_id"
            style="width: 100%"
            placeholder="请选择服务类型"
            @change="handleTypeChange"
          >
            <el-option
              v-for="item in typeListData"
              :key="item.type_id"
              :label="item.name"
              :value="item.type_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="tool" label="物料" :label-width="formLabelWidth">
          <el-tag v-if="toolListData.length === 0" type="warning"
            >未选择服务类型，或该服务类型下暂无必备物料</el-tag
          >
          <el-checkbox-group
            v-if="toolListData.length !== 0"
            v-model="form.tool"
          >
            <el-checkbox
              v-for="item in toolListData"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          prop="address_id"
          label="服务地址"
          :label-width="formLabelWidth"
        >
          <!-- <el-select
            v-model="form.address_id"
            style="width: 100%"
            placeholder="请选择服务地址"
            @change="handleChange"
          >
            <el-option
              v-for="item in locListData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <el-cascader
            style="width:100%"
            :options="locListData"
            :props="optionProps"
            v-model="form.address_id"
            @change="handleAddressChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
          prop="photo_id"
          label="拍摄点位"
          :label-width="formLabelWidth"
        >
          <el-tag v-if="photoListData.length === 0" type="warning"
            >未选择服务地址，或该服务地址下暂无拍摄点位</el-tag
          >
          <el-checkbox-group
            v-if="photoListData.length !== 0"
            v-model="form.photo_id"
          >
            <el-checkbox
              v-for="item in photoListData"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item
              prop="age_min"
              label="适合最小年龄"
              :label-width="formLabelWidth"
            >
              <el-input
                type="number"
                min="0"
                placeholder="该项目适合的最小年龄"
                @change="handleChange"
                v-model="form.age_min"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="age_max"
              label="适合最大年龄"
              :label-width="formLabelWidth"
            >
              <el-input
                type="number"
                min="1"
                placeholder="该项目适合的最大年龄"
                @change="handleChange"
                v-model="form.age_max"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item
              prop="weight_min"
              label="适合最小体重"
              :label-width="formLabelWidth"
            >
              <el-input
                type="number"
                min="0"
                @change="handleChange"
                v-model="form.weight_min"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              prop="weight_max"
              label="最大体重"
              :label-width="formLabelWidth"
            >
              <el-input
                type="number"
                min="1"
                @change="handleChange"
                v-model="form.weight_max"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              prop="height_min"
              label="最小身高"
              :label-width="formLabelWidth"
            >
              <el-input
                type="number"
                min="0"
                @change="handleChange"
                v-model="form.height_min"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              prop="height_max"
              label="最大身高"
              :label-width="formLabelWidth"
            >
              <el-input
                type="number"
                min="1"
                @change="handleChange"
                v-model="form.height_max"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          prop="weather"
          label="适合天气"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入该项目适合的天气"
            @change="handleChange"
            v-model="form.weather"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="hour"
          label="课程时长"
          :label-width="formLabelWidth"
        >
          <el-input
            type="number"
            min="0"
            placeholder="请输入课程时长（小时）"
            @change="handleChange"
            v-model="form.hour"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="teacher"
          label="教员分工"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="checkedGuide"
            style="width: 100%"
            placeholder="请选择教员"
            @change="handleGuideChange"
            multiple
          >
            <el-option
              v-for="item in guideListData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-table
            border
            v-if="guideFormList.length !== 0"
            class="inner-table"
            :data="guideFormList"
          >
            <el-table-column label="教员名称" prop="name"></el-table-column>
            <el-table-column label="人数" prop="num">
              <template v-slot="scope">
                <el-input
                  size="mini"
                  min="1"
                  type="number"
                  style="width:80px"
                  @change="handleChange"
                  v-model="scope.row.num"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item
          prop="reward"
          label="课程奖励"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入课程奖励"
            @change="handleChange"
            v-model="form.reward"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="addOrEdit === 2"
          prop="status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>

        <template>
          <div class="quill">
            <div style="width: 114px;text-align: right;margin-right: 12px;">
              教学目标
            </div>
            <!-- <haha v-model="form.content.teach_goal"></haha> -->
            <quill-editor
              style="width: 100%;"
              v-model="form.content.teach_goal"
              :options="editorOption"
            >
            </quill-editor>
          </div>
        </template>
        <template>
          <div class="quill">
            <div style="width: 114px;text-align: right;margin-right: 12px;">
              教学重点
            </div>
            <!-- <haha v-model="form.content.teach_goal"></haha> -->
            <quill-editor
              style="width: 100%;"
              v-model="form.content.teach_important_point"
              :options="editorOption"
            >
            </quill-editor>
          </div>
        </template>
        <template>
          <div class="quill">
            <div style="width: 114px;text-align: right;margin-right: 12px;">
              教学难点
            </div>
            <!-- <haha v-model="form.content.teach_goal"></haha> -->
            <quill-editor
              style="width: 100%;"
              v-model="form.content.teach_difficulty"
              :options="editorOption"
            >
            </quill-editor>
          </div>
        </template>
        <template>
          <div class="quill">
            <div style="width: 114px;text-align: right;margin-right: 12px;">
              教学方法
            </div>
            <!-- <haha v-model="form.content.teach_goal"></haha> -->
            <quill-editor
              style="width: 100%;"
              v-model="form.content.teach_method"
              :options="editorOption"
            >
            </quill-editor>
          </div>
        </template>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="confirmAE()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import lodash from 'lodash'

import {
  getSubList,
  toolList,
  photoList,
  addSub,
  getInfo,
  editSub,
  delSub,
  guideList,
  serviceLocList,
} from '@/api/edu/subject.js'
import { typeList } from '@/api/edu/siteservice.js'
import { getLocList } from '@/api/edu/location.js'
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      dialogLog: {
        state: false,
      },
      // 富文本编辑器配置
      editorOption: {
        placeholder: '请输入内容',
        modules: {
          toolbar: [
            ['bold', , 'underline', 'strike'], // 加粗 斜体 下划线 删除线

            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表

            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色

            [{ align: [] }], // 对齐方式
          ],
        },
      },
      toolListData: [],
      photoListData: [],
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      btnLoading: false,
      spcateList: [],
      serviceList: [],
      query: {
        page: 1,
        page_code: '',
        page_num: 7,
      },
      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        name: {
          required: true,
          message: '请输入服务项目名称',
          trigger: 'blur',
        },
        age_min: {
          required: true,
          message: '请输入适合最小年龄',
          trigger: 'blur',
        },
        // age_max: {
        //   required: true,
        //   message: '请输入适合最大年龄',
        //   trigger: 'blur',
        // },
        type_id: {
          required: true,
          message: '请选择服务类型',
          trigger: 'change',
        },
        address_id: {
          required: true,
          message: '请选择服务地址',
          trigger: 'change',
        },
        hour: {
          required: true,
          message: '请输入课程时长',
          trigger: 'blur',
        },
      },
      title: '添加服务项目',
      addOrEdit: 1,
      formLabelWidth: '120px',
      guideListData: [],
      optionsTask: [],
      optionProps: {
        value: 'id',
        expandTrigger: 'hover',
        label: 'cname',
        children: 'sub',
      },
      checkedGuide: [],
      form: {
        name: '',
        photo_id: [],
        hour: '',
        address_id: [],
        tool: [],
        age_max: 1,
        age_min: 60,
        weight_min: 20,
        weight_max: 200,
        height_min: 110,
        height_max: 200,
        weather: '非极端恶劣天气',

        reward: '待定',
        teacher: [],
        type_id: '',
        content: {
          teach_goal: '待填充',
          teach_important_point: '待填充',
          teach_difficulty: '待填充',
          teach_method: '待填充',
        },
      },
      locListData: [],
      tableData: [],
      typeListData: [],
      guideFormList: [],
    }
  },
  created() {
    this.getList()
    this.getOptions()
  },
  methods: {
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.page_num = val
      this.getList()
    },
    // 地址改变事件
    async handleAddressChange() {
      this.form.photo_id = []
      const { data: res } = await photoList({
        address_id: this.form.address_id,
      })
      this.photoListData = res.data
    },
    async handleTypeChange() {
      this.form.tool = []
      const { data: res } = await toolList({
        type_id: this.form.type_id,
      })
      this.toolListData = res.data
      this.toolListData.forEach((item) => {
        if (item.select === 1) {
          this.form.tool.push(Number(item.id))
        }
      })

      // this.form.tool = Array.from(new Set(this.form.tool))
    },
    handleGuideChange(arr) {
      if (this.addOrEdit === 1) {
        this.guideFormList = []
        this.guideListData.forEach((item) => {
          this.checkedGuide.forEach((id) => {
            if (item.id === id) {
              this.guideFormList.push({ id: id, name: item.name, num: 1 })
            }
          })
        })
      } else {
        this.guideFormList = []

        this.guideListData.forEach((item) => {
          arr.forEach((i) => {
            if (item.id === i.id) {
              this.guideFormList.push({ id: i.id, name: item.name, num: i.num })
            }
          })
        })
      }
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await getSubList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加服务项目'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        name: '',
        photo_id: [],
        hour: '',
        address_id: [],
        tool: [],
        age_max: 1,
        age_min: 60,
        weight_min: 20,
        weight_max: 200,
        height_min: 110,
        height_max: 200,
        weather: '非极端恶劣天气',

        reward: '待定',
        teacher: [],
        type_id: '',
        content: {
          teach_goal: '待填充',
          teach_important_point: '待填充',
          teach_difficulty: '待填充',
          teach_method: '待填充',
        },
      }
      this.guideFormList = []
      this.checkedGuide = []
      this.toolListData = []
      this.photoListData = []
      setTimeout(() => {
        this.$refs.formRef.clearValidate()
      }, 30)
      this.dialogVisible = false
    },
    async getOptions() {
      const { data: res1 } = await serviceLocList()
      const { data: res2 } = await guideList()
      const { data: res3 } = await typeList()
      this.locListData = res1.data
      this.guideListData = res2.data
      this.typeListData = res3.data.data
    },
    // 新增打开
    pAdd(row) {
      console.log(row)

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      console.log(row)
      this.dialogVisible = true
      this.title = '编辑服务项目'
      this.addOrEdit = 2
      const { data: res } = await getInfo({
        project_id: row.id,
      })
      let info = res.data
      this.form.age_max = res.data.age_max
      this.form.height_max = res.data.height_max
      this.form.height_min = res.data.height_min
      this.form.weight_min = res.data.weight_min
      this.form.weight_max = res.data.weight_max
      this.form.weather = res.data.weather

      this.form.age_min = res.data.age_min
      this.form.hour = res.data.hour
      this.form.name = res.data.name
      this.form.address_id = this.cascaderRepaint(
        res.data.address_id,
        this.locListData
      )

      this.form.reward = res.data.reward
      this.form.status = res.data.status
      this.form.project_id = res.data.id
      this.form.type_id = res.data.type_id
      let arr = []
      let arrNum = []
      res.data.teacher_arr.forEach((item) => {
        arr.push(item.id)
        arrNum.push({ num: Number(item.num), id: item.id })
      })

      //console.log(arrNum)

      // console.log(arr)
      this.checkedGuide = Array.from(new Set(arr))
      let tool = res.data.tool
      for (let i = 0; i < tool.length; i++) {
        tool[i] = Number(tool[i])
      }
      this.form.tool = tool
      const { data: res2 } = await toolList({
        type_id: this.form.type_id,
      })
      this.toolListData = res2.data
      this.form.content = res.data.info[0]
      let photo_id = res.data.photo_id.split(',')
      console.log(photo_id)
      for (let i = 0; i < photo_id.length; i++) {
        photo_id[i] = Number(photo_id[i])
      }
      const { data: res1 } = await photoList({
        address_id: this.form.address_id,
      })
      this.photoListData = res1.data
      this.form.photo_id = photo_id

      // this.handleAddressChange()

      this.handleGuideChange(arrNum)
    },
    cascaderRepaint(key, treeData) {
      let arr = [] // 在递归时操作的数组
      let returnArr = [] // 存放结果的数组
      let depth = 0 // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1) //将目前匹配的数组，截断并保存到结果数组，
            break
          } else {
            if (childrenData[j].sub) {
              depth++
              childrenEach(childrenData[j].sub, depth)
            }
          }
        }
        return returnArr
      }
      return childrenEach(treeData, depth)
    },
    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delSub({
            project_id: row.id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加

            //this.form.teacher = this.guideFormList

            let teacher = this.guideFormList
            teacher.forEach((item) => {
              this.form.teacher.push({ id: item.id, num: item.num })
            })
            let query = lodash.cloneDeep(this.form)
            query.teacher = JSON.stringify(query.teacher)
            query.content = JSON.stringify(query.content)
            query.tool = JSON.stringify(query.tool)
            query.photo_id = JSON.stringify(query.photo_id)
            query.address_id = query.address_id[query.address_id.length - 1]
            this.btnLoading = true
            const { data: res } = await addSub(query)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.btnLoading = false
              this.cancelAE()
            } else {
              this.$message.error(res.message)
              this.btnLoading = false
            }
            this.btnLoading = false
          } else {
            // 编辑
            let teacher = this.guideFormList
            teacher.forEach((item) => {
              this.form.teacher.push({ id: item.id, num: item.num })
            })
            let query = lodash.cloneDeep(this.form)
            query.teacher = JSON.stringify(query.teacher)

            query.content = JSON.stringify(query.content)
            query.tool = JSON.stringify(query.tool)
            query.photo_id = JSON.stringify(query.photo_id)
            console.log(query.address_id instanceof Array)
            if (query.address_id instanceof Array) {
              query.address_id = query.address_id[query.address_id.length - 1]
            }
            console.log(query)
            this.btnLoading = true

            const { data: res } = await editSub(query)
            if (res.code === 1) {
              this.$message.success('编辑成功')
              this.btnLoading = false

              this.cancelAE()
              this.getList()
            } else {
              this.$message.error(res.message)
              this.btnLoading = false
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped>
>>> .customWidth {
  width: 80%;
}
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
.inner-table {
  font-size: 14px !important;
  font-weight: 400;
  margin-top: 10px;
  border-radius: 3px;
}
.inner-table >>> .el-table__cell {
  padding: 3px 0;
}
.quill {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
}
.quill >>> .ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.quill .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.quill >>> .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc;
  height: 125px;
  overflow: auto;
}
</style>
