import { post } from '@/utils/request'

//获取站点列表
export function getSubList(data) {
    return post('/edu/project/projectList', data)
}
//添加站点
export function addSub(data) {
    return post('/edu/project/addProject', data)
}
//站点回显
export function getInfo(data) {
    return post('/edu/project/projectInfo', data)
}

//站点编辑
export function editSub(data) {
    return post('/edu/project/editProject', data)
}
//站点删除
export function delSub(data) {
    return post('/edu/project/delProject', data)
}
// 拍摄点位列表

export function photoList(data) {
    return post('/edu/project/photoList', data)
}
// 服务地点列表
export function serviceLocList(data) {
    return post('/edu/photo_place/addressList', data)
}
// 物料列表
export function toolList(data) {
    return post('/edu/project/toolList', data)
}
// 教员列表
export function guideList(data) {
    return post('/edu/Edu_Coach_Type/coachTypeList', data)
}