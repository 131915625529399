import { post } from '@/utils/request'

//获取站点服务列表
export function serviceList(data) {
    return post('/edu/site_service/serviceList', data)
}
// 获取站点服务分类列表

export function typeList(data) {
    return post('/edu/site_service/typeList', data)
}
//站点列表
export function siteList(data) {
    return post('/edu/site_service/siteList', data)
}

//站点模型列表
export function modelList(data) {
    return post('/edu/site_service/modelList', data)
}
//添加站点服务
export function addService(data) {
    return post('/edu/site_service/addService', data)
}
// 站点服务详情
export function serviceInfo(data) {
    return post('/edu/site_service/serviceInfo', data)
}
//编辑站点服务
export function editService(data) {
    return post('/edu/site_service/editService', data)
}
//删除站点服务
export function delService(data) {
    return post('/edu/site_service/delService', data)
}
// 根据模型id获取规格
export function getModel(data) {
    return post('/edu/spec_model/getModel', data)
}